const copyPasteContactDetails = () => {
  const checkbox = document.querySelector(".registration-form-checkbox-container input")

  if (checkbox) {
    const exhibitorFirstName = document.getElementById("exhibitor_first_name");
    const exhibitorLastName = document.getElementById("exhibitor_last_name");
    const exhibitorCompany = document.getElementById("exhibitor_company");
    const exhibitorRole = document.getElementById("exhibitor_role");
    const exhibitorEmail = document.getElementById("exhibitor_email");
    
    checkbox.addEventListener('change', (event) => {
      if(event.target.checked) {
        const userFirstName = document.getElementById("exhibitor_user_attributes_first_name");
        const userLastName = document.getElementById("exhibitor_user_attributes_last_name");
        const userCompany = document.getElementById("exhibitor_user_attributes_company");
        const userRole = document.getElementById("exhibitor_user_attributes_role");
        const userEmail = document.getElementById("exhibitor_user_attributes_email");

        exhibitorFirstName.value = userFirstName.value;
        exhibitorLastName.value = userLastName.value;
        exhibitorCompany.value = userCompany.value;
        exhibitorRole.value = userRole.value;
        exhibitorEmail.value = userEmail.value;
      } else {
        exhibitorFirstName.value = "";
        exhibitorLastName.value = "";
        exhibitorCompany.value = "";
        exhibitorRole.value = "";
        exhibitorEmail.value = "";
      }
    });
  }
}

export { copyPasteContactDetails };