const initFormValidation = () => {

  const password = document.getElementById("exhibitor_user_attributes_password");
  const passwordConfirmation = document.getElementById("exhibitor_user_attributes_password_confirmation");

  if (passwordConfirmation) {
    passwordConfirmation.addEventListener("change", (e) => {
      if (passwordConfirmation.value !== password.value) {
        Swal.fire({html: "Le mot de passe et sa confirmation doivent être strictement identiques"});
        passwordConfirmation.value = ""
      }
    })
  }

  const formSubmitButton = document.querySelector('.new-exhibitor-submit');
  const emailRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const userEmail = document.getElementById('exhibitor_user_attributes_email');
  if (userEmail) {
    formSubmitButton.addEventListener("click", (e) => {
      if (!userEmail.value.toLowerCase().match(emailRegex)) {
        e.preventDefault();
        Swal.fire({html: "L'adresse e-mail du responsable du dossier n'est pas valide"});
      }
    })
  }

  const exhibitorEmail = document.getElementById('exhibitor_email');
  if (exhibitorEmail) {
    formSubmitButton.addEventListener("click", (e) => {
      if (!exhibitorEmail.value.toLowerCase().match(emailRegex)) {
        e.preventDefault();
        Swal.fire({html: "L'adresse e-mail du responsable du stand n'est pas valide"});
      }
    })
  }


  // const telValidation = () => {
  //   const telFixe = document.getElementById("exhibitor_phone_number");

  //   if (telFixe) {
  //     telFixe.addEventListener("change", (e) => {
  //       if (telFixe.value.length !== 10 || telFixe.value != parseInt(telFixe.value)) {
  //         Swal.fire({html: "Le numéro de téléphone doit contenir 10 chiffres"});
  //         telFixe.value = "";
  //       }
  //     })
  //   }

  //   const telPortable = document.getElementById("exhibitor_mobile_phone_number");

  //   if (telPortable) {
  //     telPortable.addEventListener("change", (e) => {
  //       if (telPortable.value.length !== 10 || telPortable.value != parseInt(telPortable.value)) {
  //         Swal.fire({html: "Le numéro de téléphone doit contenir 10 chiffres"});
  //         telPortable.value = "";
  //       }
  //     })
  //   }
  // };

  // telValidation();

  // window.telValidation = () => {
  //   telValidation();
  // }

  window.productCreateFormValidation = () => {

    const produtsCheckboxes = document.querySelectorAll(".exhibitor_product_types_product_types .form-check input")
    const newExhibitorProductsBtn = document.getElementById("submit-new-exhibitor-products");

    const verifyCheckboxes = (btn) => {
      btn.addEventListener('click', (e) => {
        let checkedProducts = [];
        produtsCheckboxes.forEach((cb) => {
          if (cb.checked) {
            checkedProducts.push(cb);
          }
        })
        if (checkedProducts.length === 0) {
          e.preventDefault();
          Swal.fire({html: "Veuillez sélectionner au moins un type de produits"});
          verifyCheckboxes(newExhibitorProductsBtn);
        }
      });
    }

    if (newExhibitorProductsBtn) {
      verifyCheckboxes(newExhibitorProductsBtn);
    }
  };

  window.productEditFormValidation = () => {

    const editProductsCheckboxes = document.querySelectorAll(".exhibitor_product_types_product_types .form-check input")
    const editExhibitorProductsBtn = document.querySelector(".edit-exhibitor-products-valider");

    const verifyCheckboxes = (btn) => {
      btn.addEventListener('click', (e) => {
        let checkedProducts = [];
        editProductsCheckboxes.forEach((cb) => {
          if (cb.checked) {
            checkedProducts.push(cb);
          }
        })
        if (checkedProducts.length === 0) {
          e.preventDefault();
          Swal.fire({html: "Veuillez sélectionner au moins un type de produits"});
          verifyCheckboxes(editExhibitorProductsBtn);
        }
      });
    }

    if (editExhibitorProductsBtn) {
      verifyCheckboxes(editExhibitorProductsBtn);
    }
  };

  window.commandsFormValidation = () => {
    const quantityInputs = document.querySelectorAll(".quantities input");

    quantityInputs.forEach((input) => {
      input.addEventListener("change", (e) => {
        if (input.value != parseInt(input.value) || input.value >= 30 || input.value <= 0 ) {
          Swal.fire({html: "La quantité doit être un nombre entier entre 1 et 30"});
          input.value = "";
        }
      })
    })
  };

  window.estimateFormValidation = () => {
    const estimateFormSubmit = document.querySelector(".new-estimate-submit");
    const estimateSociete = document.getElementById("estimate_societe");
    const estimateDomaine = document.getElementById("estimate_devis_domaine_activite");
    const estimatePrenom = document.getElementById("estimate_first_name");
    const estimateNom = document.getElementById("estimate_last_name");
    const estimateEmail = document.getElementById("estimate_email");

    estimateFormSubmit.addEventListener("click", (e) => {
      if (estimateSociete.value === "") {
        e.preventDefault();
        Swal.fire({html: "Veuillez renseigner le nom de la société"});
      } else if (estimateDomaine.value === "") {
        e.preventDefault();
        Swal.fire({html: "Veuillez renseigner le domaine d'activité"});
      } else if (estimateNom.value === "") {
        e.preventDefault();
        Swal.fire({html: "Veuillez renseigner un nom"});
      } else if (estimatePrenom.value === "") {
        e.preventDefault();
        Swal.fire({html: "Veuillez renseigner un prénom"});
      } else if (estimateEmail.value === "") {
        e.preventDefault();
        Swal.fire({html: "Veuillez renseigner une adresse e-mail"});
      }
    })
  };

  // To update prices in the additional commands section
  let additionalCommands = document.querySelector('.my-exposant-stand-card')
  if (additionalCommands) {
    window.commandsFormValidation();
  }
};


export { initFormValidation }
